import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

function Downloader({ link }) {
    const ref = useRef(null);

    useEffect(() => {
        if (!link || !ref.current)
            return;

        ref.current.click();
    }, [link]);

    return (
        <a href={link} ref={ref} />
    );
}
Downloader.propTypes = {
    link: PropTypes.string
}

export default Downloader;