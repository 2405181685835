import React, {useEffect, useState} from 'react';
import dateFormat from 'date-fns/format';
import dateParse from 'date-fns/parse';

import Layout from '../../components/layout';
import Downloader from '../../components/downloader';

// Replace with the date timestamp of the file. File should be in this format pse_lookup_yyyy-MM-dd.csv.zip
const DUMP_DATE = '2021-03-21';

function DownloadDatabaseDump() {

    const [link, setLink] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            setLink(`/dumps/pse_lookup_${DUMP_DATE}.csv.zip`);
        }, 1000);
    }, []);

    const asOfDate = dateFormat(dateParse(DUMP_DATE, 'yyyy-MM-dd', new Date()), 'MMMM d, yyyy');

    return (
        <Layout>
            <div className="md:p-6">
                <div className="bg-white px-4 py-5">
                    <div className="m-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Database Dump
                        </h3>
                    </div>
                    <p className="m-4 text-sm leading-5 text-gray-500">Download PSE Lookup&apos;s database dump - as of {asOfDate}.</p>
                    <p className="m-4 text-sm leading-5 text-gray-500">Your download should start automatically within a few seconds.</p>
                </div>
            </div>

            <Downloader link={link}/>
        </Layout>
    );
}

export default DownloadDatabaseDump;
